<template>
  <div>
    <hedaer></hedaer>
    <div class="contactUs item">
      <div class="contactUs-title wow fadeInUp" data-wow-delay="0.3s">
        碧莲盛医疗集团总部
      </div>
      <div class="contactUs-text wow fadeInUp" data-wow-delay="0.4s">
        <span>电话：</span>400-822-1660
      </div>
      <div class="contactUs-text wow fadeInUp" data-wow-delay="0.5s">
        <span>地址：</span>北京市大兴区西红门鸿坤广场B座3号楼5层
      </div>
      <div class="contactUs-text wow fadeInUp" data-wow-delay="0.6s">
        <span>邮编：</span>100162
      </div>
      <div class="contactUs-text wow fadeInUp">
        <span>微信公众号</span>
      </div>
      <img
        class="contactUs-img wow fadeInUp"
        src="https://static.drlianzhuren.com/brandWeb//contactUs/wx.png"
        alt=""
      />
      <div class="contactUs-map wow fadeInUp">
        <div id="container" style="height: 100%; width: 100%"></div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      markerArr: [
        {
          lable: '北京市大兴区西红门鸿坤广场B座3号楼5层',
          point: [116.353269, 39.796477]
        }
      ]
    }
  },
  mounted () {
    var map = new window.BMapGL.Map('container') // 创建Map实例
    map.centerAndZoom(new window.BMapGL.Point(116.353269, 39.796477), 18) // 初始化地图,设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放fp
    var point = [] // 存放标注点经纬信息的数组
    var marker = [] // 存放标注点对象的数组
    var label = [] // 存放提示信息窗口对象的数组
    this.markerArr.forEach((item, i) => {
      point[i] = new window.BMapGL.Point(item.point[0], item.point[1])
      marker[i] = new window.BMapGL.Marker(point[i])
      map.addOverlay(marker[i])

      map.addOverlay(label[i]) // 将标注添加到地图中
    })
  }
}
</script>

<style lang="scss">
.contactUs {
  .contactUs-title {
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #3b3b3b;
    padding: 0 0 43px 0;
    margin-bottom: 48px;
    border-bottom: solid 1px #c8c8c8;
  }
  .contactUs-text {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #3b3b3b;
    margin-bottom: 20px;
    span {
      font-size: 19px;
      color: #707070;
    }
  }
}
.contactUs-img {
  width: 175px;
  height: 175px;
}
.contactUs-map {
  width: 500px;

  height: 323px;
  margin-top: 40px;
}
</style>
